(function (window, $) {
    var html = [
        '<div class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000" data-autohide="true">',
            '<div class="toast-header">',
                '<strong class="mr-auto error-color" data-toggle="toast-title"></strong>',
                '<a href="javascript:;" class="close-button" data-dismiss="toast" aria-label="Close">',
                    '<div class="bar-1"></div><div class="bar-2"></div>',
                '</a>',
            '</div>',
            '<div class="toast-body">',
                '<span class="error-color" data-toggle="toast-text"></span>',
            '</div>',
        '</div>',
    ].join('');

    window._toast = function (options) {
        var t = $(html);

        if (options.key) t.attr('id', options.key);
        if (options.delay) t.attr('data-delay', options.delay);
        t.find('[data-toggle=toast-title]').html(options.title).addClass(options.type + '-color');
        t.find('[data-toggle=toast-text]').html(options.text).addClass(options.type + '-color');

        if (options.key) $('#' + options.key).remove();

        if (options.type === 'remove') return;

        $('.toast-container').append(t);

        t.toast('show').on('hidden.bs.toast', function () { $(this).remove(); });
    };
})(window, $);