$(function () {
    var intro = $('[data-toggle=intro]'),
        selection = $('[data-toggle=selection]').hide(),
        analysis = $('[data-toggle=analysis]').hide(),
        _max = 3
        ;

    $('[data-toggle="tooltip"]').tooltip();

    (function () {
        $('[data-toggle=start-selection]').on('click', function () { $('body').attr('id', ''); selection.show(); window.scrollTo(0, 0); selection.trigger('mr.changed'); });
    })();

    (function () {
        var
            ignoreChange = false,
            count = $('[data-toggle=count]'),
            rangeFrom = $('[data-toggle=range-from-filter]'),
            rangeTo = $('[data-toggle=range-to-filter]'),
            loadFrom = $('[data-toggle=load-from-filter]'),
            loadTo = $('[data-toggle=load-to-filter]'),
            type = $('[data-toggle=type-filter]'),
            environment = $('[data-toggle=environment-filter]'),
            search = $('[data-toggle=search]'),
            table = $('[data-toggle=robots] tr[data-id]'),
            reset = $('[data-toggle=reset-filter]')
            ;

        function onFilterChange() {
            if (ignoreChange) return;

            var rangeFromValue = parseInt(rangeFrom.val()),
                rangeToValue = parseInt(rangeTo.val()),
                loadFromValue = parseInt(loadFrom.val()),
                loadToValue = parseInt(loadTo.val()),
                typeValue = type.filter(':checked').map(function () { return $(this).val(); }).get(),
                environmentValue = environment.filter(':checked').val(),
                searchValue = (search.val() || '').toLowerCase()
                ;

            table.each(function () {
                var r = $(this);

                var rangeItemValue = parseInt(r.attr('data-range')),
                    loadItemValue = parseInt(r.attr('data-load-maximum')),
                    typeItemValue = r.attr('data-type'),
                    subtypeItemValue = r.attr('data-subtype'),
                    environmentItemValue = r.attr('data-environments').split('|'),
                    searchItemValue = r.attr('data-search')
                    ;

                var visible = true;

                if (visible) {
                    if (rangeFromValue > rangeItemValue || rangeItemValue > rangeToValue)
                        visible = false;
                }

                if (visible) {
                    if (loadFromValue > loadItemValue || loadItemValue > loadToValue)
                        visible = false;
                }

                if (visible) {
                    if (typeValue.length && typeValue.indexOf(typeItemValue) === -1 && typeValue.indexOf(subtypeItemValue) === -1)
                        visible = false;
                }

                if (visible) {
                    if (environmentValue && environmentValue !== 'all' && environmentItemValue.indexOf(environmentValue) === -1)
                        visible = false;
                }

                if (visible) {
                    if (searchValue && (searchItemValue || '').toLowerCase().indexOf(searchValue) === -1)
                        visible = false;
                }

                if (visible) r.show();
                else r.hide();
            });

            count.text(table.filter(':visible').length);
            table.filter(':visible').each(function (i, e) { $(e).toggleClass('even', i % 2 === 0).toggleClass('odd', i % 2 === 1); });
            clearSelection();
            selection.trigger('mr.changed');
        }

        rangeFrom.on('change', onFilterChange);
        rangeTo.on('change', onFilterChange);
        loadFrom.on('change', onFilterChange);
        loadTo.on('change', onFilterChange);
        environment.on('change', onFilterChange);
        type.on('change', onFilterChange);
        (function () {
            var timeout = null, 
                latch = false;

            search.on('input propertychange', function () {
                if (latch) return;

                latch = true;

                var e = $(this);  search.not(e).val(e.val());

                clearTimeout(timeout); timeout = setTimeout(onFilterChange, 500);

                latch = false;
            });
        })();
        setTimeout(function () {
            onFilterChange();
            count.text(table.length);
        }, 200);

        reset.on('click', function () {
            ignoreChange = true;
            $('[data-initial]').each(function () {
                var e = $(this);

                if (e.is(':checkbox') || e.is(':radio'))
                    e.prop('checked', e.attr('data-initial') === 'true');
                else
                    e.val(e.attr('data-initial'));
            });

            $('#slider-range').slider('values', 0, parseInt(rangeFrom.val()));
            $('#slider-range').slider('values', 1, parseInt(rangeTo.val()));
            $('#slider-range2').slider('values', 0, parseInt(loadFrom.val()));
            $('#slider-range2').slider('values', 1, parseInt(loadTo.val()));

            ignoreChange = false;

            onFilterChange();
        });
    })();

    var getSelected = function () { return $('[data-toggle=robots] tr[data-id]:visible [data-toggle=robot-select]:checked', selection); };
    var clearSelection = function () { $('[data-toggle=robot-select]', selection).prop('checked', false).toggleClass('disabled', false).removeAttr('data-error'); };
    (function () {
        $('[data-toggle=robots] tr[data-id] [data-toggle=robot-select]').on('click', function (ev) {
            var selected = getSelected(),
                selectedIds = selected.map(function (i, e) { return $(e).val(); }).get(),
                selectedGroups = selected.map(function (i, e) { return $(e).attr('data-group'); }).get().filter(e => e),
                selectedTypes = selected.map(function (i, e) { return $(e).attr('data-group-type'); }).get().filter(e => e),
                selectedLength = selected.length >= _max;

            var e = $(this)

            if (e.is('[data-error]')) {
                var errorText = e.attr('data-error')

                if (errorText)
                    window._toast({
                        key: 'selection-error',
                        type: 'error',
                        title: 'Error',
                        text: errorText,
                    });

                ev.preventDefault();

                return;
            }

            window._toast({ key: 'selection-error', type: 'remove' });

            $('[data-toggle=robot-select]', selection).each(function (i, el) {
                var e = $(el);

                if (selectedIds.indexOf(e.val()) !== -1) {


                                    } else if (selectedTypes.length && selectedTypes.indexOf(e.attr('data-group-type')) === -1) {

                    e.toggleClass('disabled', true).attr('data-error', 'Vertical and Horizontal robots cannot be selected for analysis at the same time.');

                } else if (selectedLength) {

                    e.toggleClass('disabled', true).attr('data-error', 'Maximum of 3 robots can be selected for analysis.');

                } else if (selectedGroups.indexOf(e.attr('data-group')) !== -1) {

                    e.toggleClass('disabled', true).attr('data-error', '');

                } else {

                    e.toggleClass('disabled', false).removeAttr('data-error');

                }
            });
        });
    })();

    (function () {
        $('[data-toggle=start-analysis]').on('click', function (ev) {
            ev.preventDefault();

            var selected = getSelected().map(function (i, e) { return $(e).val(); }).get().slice(0, _max);

            analysis.trigger('mr.selected', [selected]);
        });
    })();

    (function () {
        analysis.on('mr.selected', function (ev, data) {
            var any = data && data.length > 0;

            analysis.toggle(any);
            selection.toggle(!any);
        });

        $('[data-toggle=back-selection]').on('click', function (ev) {
            ev.preventDefault();

            analysis.hide();
            selection.show();
        });
    })();

    (function () {
        var colLeft = $('.col-left-robots-list'),
            colRight = $('.table-robots-list'),
            colRightTable = $('.table-robots-list > table')
            ;

        var leftColHeight = function () {
            colRight.css('min-height', '');
            colRight.css('padding-right', '');

            var width = $(window).width(),
                left = colLeft.outerHeight(true) - 20;

            if (width >= 1200) {
                colRight.css('min-height', left);

                if (colRightTable.height() < colRight.height())
                    colRight.css('padding-right', '0px');
            }
        };

        var leftColDelayedTimeout = null;
        var leftColDelayed = function () {
            if (leftColDelayedTimeout) clearTimeout(leftColDelayedTimeout);

            leftColDelayedTimeout = setTimeout(leftColHeight, 10);
        };

        $(window).resize(leftColDelayed);

        selection.on('mr.changed', leftColDelayed);

        leftColDelayed();
    })();
});