$(function () {
    var modal = $('#modal-bom'),
        select = $('#select-bom'),
        selectValue = $('.dropdown-button', select)
        ;

    var notifyError = function () {
        window._toast({
            delay: 3000,
            key: 'bom',
            type: 'error',
            title: 'Error',
            text: 'BOM not defined.',
        });
    };

    $(document).on('click', '[data-bom]', function (ev) {
        ev.preventDefault();

        var vs = $(this).attr('data-bom');

        if (!vs) {
            notifyError();
            return;
        }

        var variants = vs.split('|');

        select.find('.dropdown-item').each(function (i, el) {
            var e = $(el),
                val = e.attr('data-value');

            e.toggleClass('d-none-important', !variants.includes(val));
        });

        var first = select.find('.dropdown-item:not(.d-none-important):eq(0)');

        if (!first.length) {
            notifyError();
            return;
        }

        first.click();

        modal.modal('show');
    });

    select.on('click', '[data-value]', function (ev) {
        ev.preventDefault();

        var e = $(this);

        selectValue.attr('data-value', e.attr('data-value')).html(e.html());

        select.trigger('change');
    });

    select.on('change', function () {
        var v = selectValue.attr('data-value') + '|'
            ;

        $('[data-variants]', modal).addClass('d-none-important');
        $('[data-variants*="' + v + '"]', modal).removeClass('d-none-important');
    });

    $('[data-toggle=bom-xlsx]').on('click', function () {
        var title = selectValue.attr('data-value'),
            visible = $('[data-variants]:not(.d-none-important)', modal)
            ; 

        if (!visible.length) return;

        var rows = [];

        rows.push([
            { v: 'MODEL', t: 's', s: { font: { bold: true } } },
            { v: 'SAP CODE', t: 's', s: { font: { bold: true } } },
            { v: 'DESCRIPTION', t: 's', s: { font: { bold: true } } },
            { v: 'E-SHOP', t: 's', s: { font: { bold: true } } }
        ]);

        var cols = [
            { wch: (rows[0][0].v || '').length },
            { wch: (rows[0][1].v || '').length },
            { wch: (rows[0][2].v || '').length },
            { wch: (rows[0][3].v || '').length }
        ];

        visible.each(function () {
            var e = $(this),
                tds = e.find('td'),
                url = tds.eq(3).find('a').attr('href')
                ;

            var row = [
                { v: tds.eq(0).text(), t: 's' },
                { v: tds.eq(1).text(), t: 's' },
                { v: tds.eq(2).text(), t: 's' },
                { v: url, t: 'h', l: { Target: url, Tooltip: 'E-SHOP' }, s: { font: { underline: true, color: { rgb: '0000FF' } } } },
            ];

            rows.push(row);

            cols[0].wch = Math.max((row[0].v || '').length, cols[0].wch);
            cols[1].wch = Math.max((row[1].v || '').length, cols[1].wch);
            cols[2].wch = Math.max((row[2].v || '').length, cols[2].wch);
            cols[3].wch = Math.max((row[3].v || '').length, cols[3].wch);
        });

        var wb = XLSX.utils.book_new();

        var ws = XLSX.utils.aoa_to_sheet(rows);

        ws['!cols'] = cols;

        XLSX.utils.book_append_sheet(wb, ws, 'BOM ' + title);

        XLSX.writeFile(wb, 'BOM-' + title + '.xlsx');
    });
});