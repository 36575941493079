$(function () {
    $('[data-toggle=report-pdf]').on('click', function () {
        var preview = $(this).attr('data-preview') === 'true';

        var input = {
            print: false,
            calculation: !window._mr ? null : window._mr.calculation
        };

                input.robotImg = getBase64Image($('.robot-axes-img-container img:visible'));
        input.chartPayloadImg = getBase64Canvas($('canvas[data-toggle=chart-payload]:visible'));
        input.chartLoadStaticImg = getBase64Canvas($('canvas[data-toggle=chart-load-static]:visible'));
        input.chartLoadDynamicImg = getBase64Canvas($('canvas[data-toggle=chart-load-dynamic]:visible'));

        var form = $('[data-toggle=report-pdf-json]')
            .val(JSON.stringify(input))
            .closest('form');

        if (preview) { 
            form.attr('target', '_blank');
            form.attr('action', '/pdf/preview')
        }

        form.submit();
    });

    function getBase64Image(el) {
        if (!el.length) return null;

        var img = el[0];

        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL('image/png');
    };

    function getBase64Canvas(el) {
        if (!el.length) return null;

        var canvas = el[0];

        return canvas.toDataURL('image/png');
    };



});