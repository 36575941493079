$(function () {
    var initSlider = function (sliderSelector, v0Selector, v1Selector, min, max) {
        var slider = $(sliderSelector),
            v0 = $(v0Selector),
            v1 = $(v1Selector)
            ;

        var updateSliderTimeout = null,
            updateSliderDelayed = function () {
                if (updateSliderTimeout) clearTimeout(updateSliderTimeout);
                updateSliderTimeout = setTimeout(updateSlider, 300);
            },
            updateSlider = function () {
                var oldValues = slider.slider('values');
                var newValues = [parseInt(v0.val() || '0'), parseInt(v1.val() || '0')];

                if (isNaN(newValues[0])) newValues[0] = min;
                if (isNaN(newValues[1])) newValues[1] = max;

                if (newValues[0] > max) newValues[0] = max;
                if (newValues[1] > max) newValues[1] = max;
                if (newValues[0] < min) newValues[0] = min;
                if (newValues[1] < min) newValues[1] = min;

                if (newValues[0] > newValues[1]) {
                    v0.val(newValues[1]);
                    v1.val(newValues[0]);
                    return;
                }

                if (oldValues[0] == newValues[0] && oldValues[1] == newValues[1]) return;

                slider.slider('values', newValues);
            };

        slider.slider({
            range: true,
            min: min,
            max: max,
            values: [min, max],
            slide: function (event, ui) {
                v0.val(ui.values[0]);
                v1.val(ui.values[1]).trigger('change');
            }
        });

        v0.val(v0.attr('data-initial')).attr('min', min).attr('max', max).on('change input propertychange', updateSliderDelayed);
        v1.val(v1.attr('data-initial')).attr('min', min).attr('max', max).on('change input propertychange', updateSliderDelayed);
    };

        initSlider('#slider-range', '#amount', '#amount2', 0, 2500);
    initSlider('#slider-range2', '#amount3', '#amount4', 0, 100);
});