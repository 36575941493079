$(function () {
    var selection = $('[data-toggle=selection]'),
        analysis = $('[data-toggle=analysis]'),
        _type = '',
        _robots = [],
        _charts = {},
        _payloads = {},
        _chartPayload = null, _chartLoadStatic = null, _chartLoadDynamic = null;

    analysis.on('mr.selected', function (ev, selected, keepInputs) {
        if (!selected || !selected.length) return;

        var robots = $.grep(data.robots, function (e, i) { return selected.indexOf(e.id) !== -1; })
            .sort(function (a, b) { return a.load_rated == b.load_rated ? 0 : (a.load_rated > b.load_rated) ? 1 : -1; })
            ;

        onSelected(robots, keepInputs);
    });

    analysis.on('click', '[data-toggle=switch-on-off]', function () {
        $(this).toggleClass('off on');

        refresh();
    });

    analysis.on('click', '[data-robot-remove]', function () {
        var remove = $(this).attr('data-robot-remove');


        var selected = $.map($.grep(_robots, function (e, i) { return e.id !== remove; }), function (e, i) { return e.id; });

        analysis.trigger('mr.selected', [selected, true]);
    });

    analysis.on('click', '[data-toggle=switch]', function () {
        var e = $(this),
            dtg = e.attr('data-target-group'),
            dt = e.attr('data-target')
            ;

        e.parent().find('[data-toggle=switch]').removeClass('active');
        e.addClass('active');

        $(dtg + ' > div', analysis).addClass('disabled');
        $(dtg + ' input', analysis).prop('disabled', true);
        $('img' + dtg, analysis).removeClass('active');

        $(dt + ' > div', analysis).removeClass('disabled');
        $(dt + ' input', analysis).prop('disabled', false);
        $('img' + dt, analysis).addClass('active');
    });
    analysis.find('[data-toggle=switch].active').trigger('click');

    (function () {
        var modal = $('#payloadModal1')
            .on('show.bs.modal', function () {
                var id = $('[data-toggle=modal-input][data-type="' + _type + '"] [data-value=id]').val();

                $('[data-mode]', modal).addClass('d-none-important');
                $('[data-mode=' + (id ? 'edit' : 'add') + ']', modal).removeClass('d-none-important');
            }),
            container = $('[data-toggle=additional-payloads]', analysis),
            template = [
                '<div class="d-flex flex-row justify-content-between align-items-center add-payload-data">',
                    '<div class="add-payload-title" data-toggle="payload-name"></div>',
                    '<div class="d-flex add-payload-buttons">',
                        '<a href="javascript:;" data-toggle="payload-edit" data-id=""><img src="img/logo-edit-circle-I.svg"></a>',
                        '<a href="javascript:;" data-toggle="payload-remove" data-id="" class="close-button"><div class="bar-1"></div><div class="bar-2"></div></a>',
                    '</div>',
                '</div>'
            ].join('')
            ;

        var func = {
            uuid: function () {
                return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                );
            },
            clear: function (leaveId) {
                var root = $('[data-toggle=modal-input][data-type="' + _type + '"]', analysis),
                    func = window['getPayloadInputInitial_' + _type];

                var result = (func ? func(_robots) : {}) || {};

                for (var key in result) {
                    $('[data-value="' + key + '"]', root).val(result[key]);
                }

                if (!leaveId) $('[data-value=id]', root).val('');
                $('[data-value=name]', root).val('');

                $('[data-toggle=switch][data-info=default]', root).trigger('click');
            },
            add: function () {
                func.clear(false);

                modal.modal('show');
            },
            edit: function (payload) {
                func.clear(false);

                func.setInput(payload);

                modal.modal('show');
            },
            setInput: function (payload) {
                var root = $('[data-toggle=modal-input][data-type="' + _type + '"]', analysis);

                for (var key in payload) {
                    $('[data-value="' + key + '"]', root).val(payload[key]);
                }

                $('[data-toggle=modal-input][data-type="' + _type + '"] [data-toggle=switch]', modal).removeClass('active');
                $('[data-toggle=modal-input][data-type="' + _type + '"] [data-toggle=switch][value="' + payload['mode'] + '"]', modal).addClass('active').trigger('click');
            },
            getInput: function () {
                var result = {};

                $('[data-toggle=modal-input][data-type="' + _type + '"] [data-value]', modal).each(function () {
                    var e = $(this);

                    result[e.attr('data-value')] = e.attr('data-kind') == 'text' ? e.val() : parseFloat((e.val() || '0').replace(',', '.'));
                });

                $('[data-toggle=modal-input][data-type-all] [data-value]', modal).each(function () {
                    var e = $(this);

                    result[e.attr('data-value')] = e.attr('data-kind') == 'text' ? e.val() : parseFloat((e.val() || '0').replace(',', '.'));
                });

                result['mode'] = $('[data-toggle=modal-input][data-type="' + _type + '"] [data-toggle=switch].active', modal).attr('value');

                return result;
            },
            ensureMain: function () {
                ensureMainInput(_type);
            },
            setPayloads: function () {
                container.empty();

                for (var id in _payloads) {
                    var t = func.setPayload(_payloads[id]);

                    container.append(t);
                }

                var mainOnly = container.children('div').length < 2;

                container.parent().toggleClass('d-none-important', mainOnly);

                setMainInput(mainOnly);

                refresh();
            },
            setPayload: function (input) {
                var t = $(template);

                t.find('[data-toggle=payload-name]').text(input.name || '-');
                t.find('[data-id]').attr('data-id', input.id);
                t.find('[data-toggle=payload-remove]').toggleClass('invisible', input.id == 'main');

                return t;
            }
        };

        analysis.on('click', '[data-toggle=payload-add]', function () {
            func.ensureMain();

            func.add();
        });

        analysis.on('click', '[data-toggle=modal-reset]', function () {
            func.clear(true); 
        });

        analysis.on('click', '[data-toggle=modal-accept]', function () {
            var input = func.getInput();

            modal.modal('hide');

            if (!input.id) input.id = func.uuid();

            _payloads[input.id] = input;

            func.setPayloads();
        });

        analysis.on('click', '[data-toggle=payload-edit][data-id]', function () {
            var input = _payloads[$(this).attr('data-id')];

            if (!input) return;

            func.edit(input);
        });

        analysis.on('click', '[data-toggle=payload-remove][data-id]', function () {
            delete _payloads[$(this).attr('data-id')];

            func.setPayloads();
        });

        analysis.on('click', '[data-toggle=reset]', function () {
            for (var key in _payloads) {
                if (key === 'main') {
                    _payloads[key] = getPayloadInputInitial(_type);
                } else {
                    delete _payloads[key];
                }
            }

            func.setPayloads();
        });

        func.ensureMain();

        func.setPayloads();
    })();

    analysis.on('change input', '[data-toggle=input] [data-value]', function () {
        if ($(this).prop('readonly')) return;

        refresh();
    });

    function onSelected(robots, keepInputs) {
        if (!robots || !robots.length) return;

        if (_type !== robots[0].robot_type_code) keepInputs = false; 

        _type = robots[0].robot_type_code;
        _robots = robots;
        _analysed = $.grep(robots, function (e, i) { return e.robot_type_code === _type; });
        _charts = {
            payload: $('[data-type=' + _type + '] [data-toggle=chart-payload]', analysis).get(0),
            loadstatic: $('[data-type=' + _type + '] [data-toggle=chart-load-static]', analysis).get(0),
            loaddynamic: $('[data-type=' + _type + '] [data-toggle=chart-load-dynamic]', analysis).get(0),
        };

        if (!keepInputs) {
            _payloads = {};

            setPayloadInputInitial(_type);
        }

        refresh();
    };

    var refreshTimeout = null;
    function refresh() {
        if (refreshTimeout) clearTimeout(refreshTimeout);

        refreshTimeout = setTimeout(refreshReal, 200);
    }

    function refreshReal() {
        setType();

        setPalletizing();

        setRobotsInput();

        var error = validatePayloadInput(_type);

        if (error) return;

        var input = getCombinedInput(_type);

        var calculations = setPayloadChart(input);

        setRobots(input, calculations);

        var result = { calculations: calculations, input: input, payloads: _payloads };

        if (!window._mr) window._mr = {};

        window._mr.calculation = result;

        console.log(result);
    }

    function setPalletizing() {
        var palletizing = $('[data-toggle=palletizing]:not(.d-none-important)').find('.on').length > 0 ? 'on' : 'off';

        $('[data-palletizing]', analysis).addClass('d-none-important');
        $('[data-palletizing="' + palletizing + '"]', analysis).removeClass('d-none-important');
    }

    function setType() {
        if (!_type) return;

        $('[data-type]', analysis).addClass('d-none-important');
        $('[data-type=' + _type + ']', analysis).removeClass('d-none-important');
    }

    function setRobotsInput() {
        setRobotInput(_robots.length > 0 ? _robots[0] : null, 'robot-1');
        setRobotInput(_robots.length > 1 ? _robots[1] : null, 'robot-2');
        setRobotInput(_robots.length > 2 ? _robots[2] : null, 'robot-3');
    }

    function setRobotInput(robot, prefix) {
        if (!robot) return;

        robot.SpecialOn = $('[data-toggle=' + prefix + '-special]:not(.d-none-important)').find('.on').length > 0;
    }

    function setRobots(input, calculations) {
        setRobot(_robots.length > 0 ? _robots[0] : null, 'robot-1', (calculations || []).length > 0 ? calculations[0] : null);
        setRobot(_robots.length > 1 ? _robots[1] : null, 'robot-2', (calculations || []).length > 1 ? calculations[1] : null);
        setRobot(_robots.length > 2 ? _robots[2] : null, 'robot-3', (calculations || []).length > 2 ? calculations[2] : null);
    }

    function setRobot(robot, prefix, calculation) {
        $('[data-toggle=' + prefix + ']', analysis).toggle(!!robot);

        if (!robot) return;

        var staticError = ((calculation || {}).calculation || {}).LoadStaticError;
        var dynamicError = ((calculation || {}).calculation || {}).LoadDynamicError;
        var error = robot.robot_type_code !== _type || staticError || dynamicError;

        $('[data-toggle=' + prefix + '-name]', analysis).text(robot.name);
        $('[data-toggle=' + prefix + '-bom]', analysis).attr('data-bom', robot.variants ? robot.variants + '|' : '');
        $('[data-bind=' + prefix + '-tooltip]', analysis).attr('data-original-title', $('[data-id=' + robot.id + '] .robot-type-img', selection).prop('outerHTML')).tooltip();
        $('[data-toggle=' + prefix + '-special]', analysis).toggleClass('d-none-important', !isRobotSpecialEnabled(robot));
        $('[data-toggle=' + prefix + '-static]', analysis).text(staticError ? 'ERROR' : 'OK').toggleClass('success-color', !staticError).toggleClass('error-color', !!staticError);
        $('[data-toggle=' + prefix + '-dynamic]', analysis).text(dynamicError ? 'ERROR' : 'OK').toggleClass('success-color', !dynamicError).toggleClass('error-color', !!dynamicError);
        $('[data-toggle=' + prefix + '-result]', analysis).toggleClass('error-color-bg', !!error).toggleClass('success-color-bg', !error);
        $('[data-toggle=' + prefix + '-remove]', analysis).attr('data-robot-remove', robot.id);
    }

    function isRobotSpecialEnabled(robot) {
        if (_type == 'horizontal') return (robot.IMM || 0) > 0;
        return false;
    }

    function getPayloadInputInitial(type) {
        var func = window['getPayloadInputInitial_' + type];

        var result = (func ? func(_robots) : {}) || {};

        return result;
    }

    function setPayloadInputInitial(type) {
        var root = $('[data-toggle=input][data-type="' + type + '"]', analysis),
            result = getPayloadInputInitial(type)
            ;

        for (var key in result) {
            $('[data-value="' + key + '"]', root).val(result[key]);
        }

        $('[data-toggle=switch][data-info=default]', analysis).trigger('click');
        $('[data-toggle=palletizing-switch] [data-toggle=switch-on-off]', analysis).removeClass('on').addClass('off');
    }

    function validatePayloadInput(type) {
        var errors = [];

        $('[data-toggle=input][data-type="' + type + '"] [data-value]:enabled, [data-toggle=input][data-type-all] [data-value]:enabled', analysis).each(function () {
            var e = $(this),
                v = parseFloat((e.val() || '0').replace(',', '.'))
                ;

            if (e.is('[min]')) {
                var min = parseFloat((e.attr('min') || 0).replace(',', '.'));

                if (v < min) {
                    errors.push('\'' + e.attr('data-value') + '\' should be greater than ' + min + '.');
                }
            }

            if (e.is('[max]')) {
                var max = parseFloat((e.attr('max') || 0).replace(',', '.'));

                if (v > max) {
                    errors.push('\'' + e.attr('data-value') + '\' should be lesser than ' + max + '.');
                }
            }
        });

        if (errors.length) {
            window._toast({
                key: 'validation',
                delay: 9999999,
                type: 'error',
                title: 'Error',
                text: errors.join('<br />'),
            });
        } else {
            window._toast({
                key: 'validation',
                type: 'remove'
            });
        }

        return errors.length > 0;
    }

    function getCombinedInput(type) {
        ensureMainInput(type);

        var result = {
            Lx: 0, Ly: 0, Lz: 0,
            lx: 0, ly: 0, lz: 0,
            Sx: 0, Sy: 0, Sz: 0,
            M: 0,
            Ms: 0,
        };

        var e3 = 1.0 / Math.pow(10, 3);

        for (var key in _payloads) {
            var p = JSON.parse(JSON.stringify(_payloads[key]));

            if (p.mode === '1') {
                p.lx = ((p.M * (Math.pow((p.Sy * e3), 2) + Math.pow((p.Sz * e3), 2)) / 12) || 0);
                p.ly = ((p.M * (Math.pow((p.Sz * e3), 2) + Math.pow((p.Sx * e3), 2)) / 12) || 0);
                p.lz = ((p.M * (Math.pow((p.Sx * e3), 2) + Math.pow((p.Sy * e3), 2)) / 12) || 0);
            }

            if (!isNaN(p.M)) {
                result.M += p.M || 0;

                result.Lx += p.M * (p.Lx || 0);
                result.Ly += p.M * (p.Ly || 0);
                result.Lz += p.M * (p.Lz || 0);

                result.lx += p.lx;
                result.ly += p.ly;
                result.lz += p.lz;
            }
        }

        result.Ms = (_payloads.main || {}).Ms || 0;
        result.palletizing = (_payloads.main || {}).palletizing || false;

        if (result.M != 0) {
            if (result.Lx) result.Lx /= result.M;
            if (result.Ly) result.Ly /= result.M;
            if (result.Lz) result.Lz /= result.M;
        }

        for (var key in _payloads) {
            var p = JSON.parse(JSON.stringify(_payloads[key]));

            if (!isNaN(p.M)) {
                result.lx += (p.M * (Math.pow((p.Ly - result.Ly) * e3, 2) + Math.pow((p.Lz - result.Lz) * e3, 2))) || 0;
                result.ly += (p.M * (Math.pow((p.Lx - result.Lx) * e3, 2) + Math.pow((p.Lz - result.Lz) * e3, 2))) || 0;
                result.lz += (p.M * (Math.pow((p.Ly - result.Ly) * e3, 2) + Math.pow((p.Lx - result.Lx) * e3, 2))) || 0;
            }
        }

        if (!isNaN(result.M) && result.M != 0) {
            result.Sx = ((Math.sqrt((6 / result.M) * (-result.lx + result.ly + result.lz)) * Math.pow(10, 3)) || 0.0).toFixed(0);
            result.Sy = ((Math.sqrt((6 / result.M) * (result.lx - result.ly + result.lz)) * Math.pow(10, 3)) || 0.0).toFixed(0);
            result.Sz = ((Math.sqrt((6 / result.M) * (result.lx + result.ly - result.lz)) * Math.pow(10, 3)) || 0.0).toFixed(0);
        }

        return result;
    }

    function getDisplayCombinedInput(type) {
        var result = getCombinedInput(type);

        return result;
    }

    function ensureMainInput(type) {
        if (Object.keys(_payloads).length > 1) {

            if (_payloads.main) {
                $('[data-toggle=input][data-type-all] [data-value]', analysis).each(function () {
                    var e = $(this);

                    _payloads.main[e.attr('data-value')] = parseFloat((e.val() || '0').replace(',', '.'));
                });

                _payloads.main['palletizing'] = $('[data-toggle=palletizing]:not(.d-none-important)').find('.on').length > 0;
            }

            return;
        }

        var input = getMainInput(type);
        input.id = 'main';
        input.name = 'Main flange payload';

        _payloads['main'] = input;
    }

    function getMainInput(type) {
        var result = {};

        $('[data-toggle=input][data-type="' + type + '"] [data-value]', analysis).each(function () {
            var e = $(this);

            result[e.attr('data-value')] = parseFloat((e.val() || '0').replace(',', '.'));
        });

        $('[data-toggle=input][data-type-all] [data-value]', analysis).each(function () {
            var e = $(this);

            result[e.attr('data-value')] = parseFloat((e.val() || '0').replace(',', '.'));
        });

        result['mode'] = $('[data-toggle=input][data-type="' + type + '"] [data-toggle=switch].active', analysis).attr('value');
        result['palletizing'] = $('[data-toggle=palletizing]:not(.d-none-important)').find('.on').length > 0;

        return result;
    }

    function setMainInput(mainOnly) {
        var root = $('[data-toggle=input][data-type="' + _type + '"]', analysis);

        var payload = mainOnly ? _payloads['main'] : getDisplayCombinedInput(_type);

        $('[data-value]', root).prop('readonly', !mainOnly);

        for (var key in payload) {
            $('[data-value="' + key + '"]', root).val(payload[key]);
        }

        if (mainOnly) {
            $('[data-toggle=switch]', root).removeClass('active');
            $('[data-toggle=switch][value="' + payload['mode'] + '"]', root).addClass('active').trigger(mainOnly ? 'click' : 'xyz');

            $('[data-toggle=palletizing] [data-toggle=switch-on-off]').addClass(payload.palletizing ? 'on' : 'off').removeClass(payload.palletizing ? 'off' : 'on');
        }

    }

    function setPayloadChart(input) {
        var func = window['setPayloadChart_' + _type];

        return func ? func(input) : null;
    }

    window.getPayloadInputInitial_vertical = function (robots) {
        return {
            Lx: 0, Ly: 0, Lz: 0,
            lx: 0, ly: 0, lz: 0,
            Sx: 0, Sy: 0, Sz: 0,
            M: robots && robots.length ? Math.min.apply(Math, robots.map(function (r) { return r.load_rated; })) : 0,
            Ms: 0,
            mode: '1',
            palletizing: false,
        };
    };

    window.setPayloadChart_vertical = function (input) {
        var calculateNormal = function (input, robot) {
            var g = 9.8;

            var M = (input.M || 0);

            var IJ4 = robot.IJ4;
            var IJ5 = robot.IJ5;
            var IJ6 = robot.IJ6;

            var calculation = {
                LxyLimitByMomentJ4: Math.round(Math.sqrt(Math.pow((robot.MJ4 / (M * g) * Math.pow(10, 3)), 2) - Math.pow(robot.P, 2)) || 0),
                LxyLimitByMomentJ5: Math.round(Math.sqrt(Math.pow((robot.MJ5 / (M * g) * Math.pow(10, 3)), 2) - Math.pow(robot.P, 2)) || 0),
                LxyLimitByMomentJ6: Math.round(robot.MJ6 / (M * g) * Math.pow(10, 3)) || 0,

                LxyLimitByInertiaJ4: Math.round(Math.min(
                    Math.sqrt((IJ4 - input.lz) / M) * Math.pow(10, 3) || 0,
                    Math.sqrt((((IJ4 - Math.max(input.lx, input.ly)) / M) * Math.pow(10, 6)) - Math.pow(robot.P, 2)) || 0)),
                LxyLimitByInertiaJ5: Math.round(Math.sqrt((Math.pow((Math.sqrt((IJ5 - Math.max(input.lx, input.ly)) / M) * Math.pow(10, 3)), 2) - Math.pow(robot.P, 2))) || 0),
                LxyLimitByInertiaJ6: Math.round((Math.sqrt((IJ6 - input.lz) / M) * Math.pow(10, 3)) || 0),

                LzLimitByMomentJ4: Math.max(0, Math.round((robot.MJ4 / (M * g) * Math.pow(10, 3)) - robot.P) || 0),
                LzLimitByMomentJ5: Math.max(0, Math.round((robot.MJ5 / (M * g) * Math.pow(10, 3)) - robot.P) || 0),

                LzLimitByInertiaJ4: Math.round(((Math.sqrt((IJ4 - Math.max(input.lx, input.ly)) / M) * Math.pow(10, 3)) - robot.P) || 0),
                LzLimitByInertiaJ5: Math.round(((Math.sqrt((IJ5 - Math.max(input.lx, input.ly)) / M) * Math.pow(10, 3)) - robot.P) || 0),

                LxyCenterOfMass: Math.round(Math.pow(Math.pow(input.Lx, 2) + Math.pow(input.Ly, 2), 0.5) || 0), 
                LzCenterOfMass: Math.round(input.Lz || 0),  

                LxyCenterOfMassNotRound: Math.pow(Math.pow(input.Lx, 2) + Math.pow(input.Ly, 2), 0.5) || 0,
            };

            calculation.LxyLimitByMomentMin = Math.min(calculation.LxyLimitByMomentJ4, calculation.LxyLimitByMomentJ5, calculation.LxyLimitByMomentJ6);
            calculation.LxyLimitByInertiaMin = Math.min(calculation.LxyLimitByInertiaJ4, calculation.LxyLimitByInertiaJ5, calculation.LxyLimitByInertiaJ6);
            calculation.LzLimitByMomentMin = Math.min(calculation.LzLimitByMomentJ4, calculation.LzLimitByMomentJ5);
            calculation.LzLimitByInertiaMin = Math.min(calculation.LzLimitByInertiaJ4, calculation.LzLimitByInertiaJ5);

            calculation.LxyGraph = Math.min(calculation.LxyLimitByMomentMin, calculation.LxyLimitByInertiaMin); 
            calculation.LzGraph = Math.min(calculation.LzLimitByMomentMin, calculation.LzLimitByInertiaMin); 

            calculation.LzRadius = calculation.LzGraph + robot.P;
            calculation.LzRadiusSquared = Math.pow(calculation.LzRadius, 2); 

            calculation.LoadStaticJ4 = (M * g) * Math.sqrt(Math.pow(calculation.LxyCenterOfMassNotRound / Math.pow(10, 3), 2) + Math.pow((calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3), 2)) / robot.MJ4;
            calculation.LoadStaticJ5 = (M * g) * Math.sqrt(Math.pow(calculation.LxyCenterOfMassNotRound / Math.pow(10, 3), 2) + Math.pow((calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3), 2)) / robot.MJ5;
            calculation.LoadStaticJ6 = (M * g) * (calculation.LxyCenterOfMassNotRound) / Math.pow(10, 3) / robot.MJ6;

            calculation.LoadDynamicJ4 = ((M * ((Math.pow(calculation.LxyCenterOfMassNotRound / Math.pow(10, 3), 2)) + (Math.pow((calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3), 2)))) + Math.max(input.lx, input.ly)) / IJ4;
            calculation.LoadDynamicJ5 = ((M * ((Math.pow(calculation.LxyCenterOfMassNotRound / Math.pow(10, 3), 2)) + (Math.pow((calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3), 2)))) + Math.max(input.lx, input.ly)) / IJ5;
            calculation.LoadDynamicJ6 = ((M * Math.pow(calculation.LxyCenterOfMassNotRound / Math.pow(10, 3), 2)) + input.lz) / IJ6;

            var isOverload = function (_y) { return !isFinite(_y) || (parseFloat(_y) > parseFloat(1)); };

            calculation.LoadStaticJ4Overload = isOverload(calculation.LoadStaticJ4);
            calculation.LoadStaticJ5Overload = isOverload(calculation.LoadStaticJ5);
            calculation.LoadStaticJ6Overload = isOverload(calculation.LoadStaticJ6);
            calculation.LoadDynamicJ4Overload = isOverload(calculation.LoadDynamicJ4);
            calculation.LoadDynamicJ5Overload = isOverload(calculation.LoadDynamicJ5);
            calculation.LoadDynamicJ6Overload = isOverload(calculation.LoadDynamicJ6);

            calculation.LxyCenterOfMassMaximum = robot.LxyCOMV1 || robot.LxyCOMV2 ? (((robot.LxyCOMV1 || 0) * Math.max(1, input.M)) + (robot.LxyCOMV2 || 0)) : robot.LxyCenterOfMassMaximum;

            calculation.LzCenterOfMassError = Math.abs(calculation.LzCenterOfMass) > (robot.LzCenterOfMassMaximum || 999999);
            calculation.LxyCenterOfMassError = Math.abs(calculation.LxyCenterOfMass) > (calculation.LxyCenterOfMassMaximum || 999999);
            calculation.LxyCenterOfMassLargeInertiaError = Math.abs(calculation.LxyCenterOfMass) > (robot.LxyCenterOfMassLargeInertiaMaximum || 999999);

            calculation.MTotal = (input.M || 0) + (input.Ms || 0);

            calculation.LoadMassError = M > robot.load_rated || calculation.MTotal > robot.load_maximum;

            calculation.LoadStaticError = calculation.LoadStaticJ4Overload || calculation.LoadStaticJ5Overload || calculation.LoadStaticJ6Overload || calculation.LoadMassError;
            calculation.LoadDynamicError = calculation.LoadDynamicJ4Overload || calculation.LoadDynamicJ5Overload || calculation.LoadDynamicJ6Overload || calculation.LoadMassError;

            return calculation;
        };

        var calculatePalletizing = function (input, robot) {
            var g = 9.8;
            var deg10 = Math.sin(10 * Math.PI / 180);

            var M = (input.M || 0);

            var IJ4 = robot.IJ4M || robot.IJ4;
            var IJ5 = robot.IJ5M || robot.IJ5;
            var IJ6 = robot.IJ6M || robot.IJ6;

            var calculation = {
                LxyLimitByMomentJ4: Math.round(Math.sqrt(Math.pow((robot.MJ4 / (M * g) * Math.pow(10, 3)), 2) - Math.pow(robot.P, 2)) || 0),
                LxyLimitByMomentJ5: Math.round(Math.sqrt(Math.pow((robot.MJ5 / (M * g) * Math.pow(10, 3)), 2) - Math.pow(robot.P, 2)) || 0),
                LxyLimitByMomentJ6: Math.round(robot.MJ6 / (M * g * deg10) * Math.pow(10, 3)) || 0,

                LxyLimitByInertiaJ4: Math.round(Math.min(
                    Math.sqrt((IJ4 - input.lz) / M) * Math.pow(10, 3) || 0,
                    Math.sqrt((((IJ4 - Math.max(input.lx, input.ly)) / M) * Math.pow(10, 6)) - Math.pow(robot.P, 2)) || 0)),
                LxyLimitByInertiaJ5: Math.round(Math.sqrt((Math.pow((Math.sqrt((IJ5 - Math.max(input.lx, input.ly)) / M) * Math.pow(10, 3)), 2) - Math.pow(robot.P, 2))) || 0),
                LxyLimitByInertiaJ6: Math.round((Math.sqrt((IJ6 - input.lz) / M) * Math.pow(10, 3)) || 0),

                LzLimitByMomentJ4: Math.max(0, Math.round((robot.MJ4 / (M * g * deg10) * Math.pow(10, 3)) - robot.P) || 0),
                LzLimitByMomentJ5: Math.max(0, Math.round((robot.MJ5 / (M * g * deg10) * Math.pow(10, 3)) - robot.P) || 0),

                LzLimitByInertiaJ4: Math.round(((Math.sqrt((IJ4 - Math.max(input.lx, input.ly)) / M) * Math.pow(10, 3)) - robot.P) || 0),
                LzLimitByInertiaJ5: Math.round(((Math.sqrt((IJ5 - Math.max(input.lx, input.ly)) / M) * Math.pow(10, 3)) - robot.P) || 0),

                LxyCenterOfMass: Math.round(Math.pow(Math.pow(input.Lx, 2) + Math.pow(input.Ly, 2), 0.5) || 0), 
                LzCenterOfMass: Math.round(input.Lz || 0),  

                LxyCenterOfMassNotRound: Math.pow(Math.pow(input.Lx, 2) + Math.pow(input.Ly, 2), 0.5) || 0,
            };

            calculation.LxyLimitByMomentMin = Math.min(calculation.LxyLimitByMomentJ4, calculation.LxyLimitByMomentJ5, calculation.LxyLimitByMomentJ6);
            calculation.LxyLimitByInertiaMin = Math.min(calculation.LxyLimitByInertiaJ4, calculation.LxyLimitByInertiaJ5, calculation.LxyLimitByInertiaJ6);
            calculation.LzLimitByMomentMin = Math.min(calculation.LzLimitByMomentJ4, calculation.LzLimitByMomentJ5);
            calculation.LzLimitByInertiaMin = Math.min(calculation.LzLimitByInertiaJ4, calculation.LzLimitByInertiaJ5);

            calculation.LoadStaticJ4 = calculation.LxyCenterOfMassNotRound == 0
                ? (M * g * deg10 * (calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3) / robot.MJ4)
                : (M * g *
                    (
                        10 / 180 * Math.PI >= Math.atan((calculation.LzCenterOfMass + robot.P) / calculation.LxyCenterOfMassNotRound)
                            ? (Math.sqrt(Math.pow((calculation.LxyCenterOfMassNotRound / Math.pow(10, 3)), 2) + Math.pow(((calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3)), 2)))
                            : (Math.sqrt(Math.pow((calculation.LxyCenterOfMassNotRound / Math.pow(10, 3)), 2) + Math.pow(((calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3)), 2)) * Math.cos(Math.atan((calculation.LzCenterOfMass + robot.P) / calculation.LxyCenterOfMassNotRound) - (10 / 180 * Math.PI)))
                    )
                    / robot.MJ4
                );

            calculation.LoadStaticJ5 = calculation.LxyCenterOfMassNotRound == 0
                ? (M * g * deg10 * (calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3) / robot.MJ5)
                : (M * g *
                    (
                        10 / 180 * Math.PI >= Math.atan((calculation.LzCenterOfMass + robot.P) / calculation.LxyCenterOfMassNotRound)
                            ? (Math.sqrt(Math.pow((calculation.LxyCenterOfMassNotRound / Math.pow(10, 3)), 2) + Math.pow(((calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3)), 2)))
                            : (Math.sqrt(Math.pow((calculation.LxyCenterOfMassNotRound / Math.pow(10, 3)), 2) + Math.pow(((calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3)), 2)) * Math.cos(Math.atan((calculation.LzCenterOfMass + robot.P) / calculation.LxyCenterOfMassNotRound) - (10 / 180 * Math.PI)))
                    )
                    / robot.MJ5
                );

            calculation.LoadStaticJ6 = (M * g * deg10) * (calculation.LxyCenterOfMassNotRound) / Math.pow(10, 3) / robot.MJ6;

            calculation.LoadDynamicTempA = Math.pow(calculation.LxyCenterOfMassNotRound / Math.pow(10, 3), 2);
            calculation.LoadDynamicTempB = Math.pow((calculation.LzCenterOfMass + robot.P) / Math.pow(10, 3), 2);
            calculation.LoadDynamicTempC = Math.max(input.lx, input.ly);

            calculation.LoadDynamicTemp =
                (M * (calculation.LoadDynamicTempA + calculation.LoadDynamicTempB))
                + calculation.LoadDynamicTempC;

            calculation.LoadDynamicJ4 = calculation.LoadDynamicTemp / IJ4;

            calculation.LoadDynamicJ5 = calculation.LoadDynamicTemp / IJ5;

            calculation.LoadDynamicJ6 = ((M * Math.pow(calculation.LxyCenterOfMassNotRound / Math.pow(10, 3), 2)) + input.lz) / IJ6;

            var isOverload = function (_y) { return !isFinite(_y) || (parseFloat(_y) > parseFloat(1)); };

            calculation.LoadStaticJ4Overload = isOverload(calculation.LoadStaticJ4);
            calculation.LoadStaticJ5Overload = isOverload(calculation.LoadStaticJ5);
            calculation.LoadStaticJ6Overload = isOverload(calculation.LoadStaticJ6);
            calculation.LoadDynamicJ4Overload = isOverload(calculation.LoadDynamicJ4);
            calculation.LoadDynamicJ5Overload = isOverload(calculation.LoadDynamicJ5);
            calculation.LoadDynamicJ6Overload = isOverload(calculation.LoadDynamicJ6);

            calculation.LxyCenterOfMassMaximum = robot.LxyCOMV1 || robot.LxyCOMV2 ? (((robot.LxyCOMV1 || 0) * Math.max(1, input.M)) + (robot.LxyCOMV2 || 0)) : robot.LxyCenterOfMassMaximum;

            calculation.LzCenterOfMassError = Math.abs(calculation.LzCenterOfMass) > (robot.LzCenterOfMassMaximum || 999999);
            calculation.LxyCenterOfMassError = Math.abs(calculation.LxyCenterOfMass) > (calculation.LxyCenterOfMassMaximum || 999999);
            calculation.LxyCenterOfMassLargeInertiaError = Math.abs(calculation.LxyCenterOfMass) > (robot.LxyCenterOfMassLargeInertiaMaximum || 999999);

            calculation.MTotal = (input.M || 0) + (input.Ms || 0);

            calculation.LoadMassError =  calculation.MTotal > robot.load_maximum;

            calculation.LoadStaticError = calculation.LoadStaticJ4Overload || calculation.LoadStaticJ5Overload || calculation.LoadStaticJ6Overload || calculation.LoadMassError;
            calculation.LoadDynamicError = calculation.LoadDynamicJ4Overload || calculation.LoadDynamicJ5Overload || calculation.LoadDynamicJ6Overload || calculation.LoadMassError;

            calculation.LxyCondition0Value = calculation.LxyCenterOfMass == 0 ? Number.MAX_VALUE : Math.atan((calculation.LzCenterOfMass + robot.P) / calculation.LxyCenterOfMass) / Math.PI * 180;
            calculation.LxyCondition0 = calculation.LxyCondition0Value <= 10;
            calculation.LxyConditionA = calculation.LxyLimitByMomentMin >= calculation.LxyLimitByInertiaMin;
            calculation.LxyConditionB = calculation.LxyLimitByMomentJ4 >= calculation.LxyLimitByMomentJ6 || calculation.LxyLimitByMomentJ5 >= calculation.LxyLimityByMomentJ6;
            calculation.LxyCondition = calculation.LxyCondition0 || calculation.LxyConditionA || calculation.LxyConditionB;

            calculation.LxyGraph = calculation.LxyCondition
                ? Math.min(calculation.LxyLimitByMomentMin, calculation.LxyLimitByInertiaMin)
                : (calculation.LoadStaticJ4 == 0 ? 0 : (calculation.LxyCenterOfMass / calculation.LoadStaticJ4))
                ; 
            calculation.LzGraph = Math.min(calculation.LzLimitByMomentMin, calculation.LzLimitByInertiaMin); 

            calculation.LzRadius = calculation.LzGraph + robot.P;
            calculation.LzRadiusSquared = Math.pow(calculation.LzRadius, 2); 

            return calculation;
        };


        var calculate = function (input, robot) {
            return input.palletizing
                ? calculatePalletizing(input, robot)
                : calculateNormal(input, robot)
                ;
        };


        var calculations = $.map(_analysed, function (robot, i) {
            return {
                calculation: calculate(input, robot),
                robot: robot
            };
        });

        var count = calculations.length;
        var resolution = 1;
        var xMax = Math.max.apply(Math, calculations.map(function (o) { return o.calculation.LzGraph; }));
        var yMax = Math.max.apply(Math, calculations.map(function (o) { return o.calculation.LxyGraph; }));

        if (count) {
            xMax = Math.max(xMax, calculations[0].calculation.LzCenterOfMass);
            yMax = Math.max(yMax, calculations[0].calculation.LxyCenterOfMass);
        }

        xMax = Math.ceil((xMax + 1) / 20) * 20;
        yMax = Math.ceil((yMax + 1) / 20) * 20;

        if (!isFinite(xMax) || !isFinite(yMax)) {
            if (_chartPayload) _chartPayload.destroy(); _chartPayload = null;
            if (_chartLoadStatic) _chartLoadStatic.destroy(); _chartLoadStatic = null;
            if (_chartLoadDynamic) _chartLoadDynamic.destroy(); _chartLoadDynamic = null;
            return;
        }

        (function () {
            var labels = [];
            var datasets = $.map(calculations, function (calculation, i) {
                return {
                    label: calculation.robot.name,
                    data: [],
                    parsing: false,
                    borderWidth: 4,
                    borderColor: i === 0 ? '#4595e0' : i === 1 ? '#53b2aa' : '#1b4874',
                }
            });

            for (var x = 0; x < xMax; x += resolution) {
                for (var d = 0; d < count; d++) {
                    var calculationItem = calculations[d];
                    var calculation = calculationItem.calculation;

                    var xq = Math.pow(x + calculationItem.robot.P, 2);

                    var y = Math.sqrt(calculation.LzRadiusSquared - xq); 

                    datasets[d].data.push({ x: x, y: !isFinite(y) ? null : Math.min(y, calculation.LxyGraph) }); 
                }

                labels.push(x);
            }

            if (count) {
                var image = new Image();
                image.width = 26;
                image.height = 26;
                image.src = $('.center-of-mass-image').attr('src');

                datasets.push({
                    label: 'Center of mass',
                    parsing: false,
                    data: [{
                        x: calculations[0].calculation.LzCenterOfMass,
                        y: calculations[0].calculation.LxyCenterOfMass,
                    }],
                    pointStyle: [image],
                    elements: {
                        point: {
                            radius: 1,
                            hitRadius: 10,
                        }
                    },
                });
            }

            var config = {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: datasets,
                },
                plugins: [
                    {
                        beforeUpdate: function (chart, args, options) {
                            var width = chart.width,
                                height = chart.height
                                ;

                            if (!width || !height) return;

                            var xMax = chart.options.scales.x.max,
                                yMax = chart.options.scales.y.max
                                ;

                            if (!xMax || !yMax) return;

                            var xRatio = (width / parseFloat(xMax)),
                                yRatio = (height / parseFloat(yMax))
                                ;

                            if (!isFinite(xRatio) || !isFinite(yRatio)) return;

                            if (xRatio.toFixed(1) === yRatio.toFixed(1))
                                return false;
                            else if (xRatio > yRatio) {
                                var newValue = Math.ceil(width / yRatio);

                                if (chart.data.labels.length < newValue) {
                                    var max = chart.data.labels[chart.data.labels.length - 1];
                                    for (var i = max; i <= newValue; i++) {
                                        chart.data.labels.push(i);
                                    }
                                } else if (chart.data.labels.length > newValue) {
                                    var del = chart.data.labels.length - newValue;

                                    chart.data.labels.splice(chart.data.lables.length - del, del);
                                }

                                chart.scales.x._userMax = chart.scales.x.max = chart.options.scales.x.max = newValue;

                            } else if (xRatio < yRatio) {
                                chart.scales.y._userMax = chart.scales.y.max = chart.options.scales.y.max = Math.ceil(height / xRatio);

                            }

                            setTimeout(function () { chart.render() }, 1000);
                        }
                    }
                ],
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            min: 0, max: yMax,
                            grid: {
                                display: true,
                                color: '#c9c9c9',
                                borderColor: '#c9c9c9',
                                tickColor: '#c9c9c9',
                                drawBorder: true,
                                borderDash: [2, 2],
                                tickBorderDash: [0],
                            },
                            ticks: {
                                maxRotation: 0,
                                color: '#868889',
                                font: {
                                    size: 10,
                                }
                            },
                            title: {
                                display: true,
                                text: 'Lxy [mm]',
                                color: '#868889',
                            },
                        },
                        x: {
                            min: 0, max: xMax,
                            grid: {
                                display: true,
                                color: '#c9c9c9',
                                borderColor: '#c9c9c9',
                                tickColor: '#c9c9c9',
                                drawBorder: true,
                                borderDash: [2, 2],
                                tickBorderDash: [],
                            },
                            ticks: {
                                maxRotation: 0,
                                color: '#868889',
                                autoSkipPadding: 120,
                                font: {
                                    size: 10,
                                }
                            },
                            title: {
                                display: true,
                                text: 'Lz [mm]',
                                color: '#868889',
                            },
                        }
                    },
                    interaction: {
                        mode: 'nearest',
                        intersect: false,
                    },
                    elements: {
                        point: {
                            radius: 0,
                            hitRadius: 3,
                            hoverRadius: 6,
                            hoverBorderColor: '#ffffff',
                            hoverBorderWidth: 6,
                            hoverBackgroundColor: 'transparent'
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            backgroundColor: '#ffffff',
                            borderRadius: 10,
                            bodyColor: '#868889',
                            displayColors: false,
                            caretPadding: 12,
                            padding: {
                                left: 12,
                                right: 12,
                                top: 4,
                                bottom: 4,
                            },
                            callbacks: {
                                title: function () { return ''; },
                                label: function (context) {
                                    return context.dataset.label + ': Lz: ' + parseFloat(context.parsed.x).toFixed(2) + ' mm, Lxy: ' + parseFloat(context.parsed.y).toFixed(2) + ' mm';
                                }
                            }
                        }
                    }
                }
            };

            if (_chartPayload) _chartPayload.destroy();
            _chartPayload = new Chart(_charts.payload, config);
        })();

        (function () {
            var formatLoad = function (_x, y, overload) {
                var result = {
                    x: Math.min(Math.round(_x == 0 ? 0 : ((_x || 1) * 100)), 100.0),
                    y: y,
                    overload: overload,
                };

                return result;
            };

            var createChart = function (chartInstance, canvas, datasets) {
                var config = {
                    type: 'bar',
                    data: {
                        labels: ['J4', 'J5', 'J6'],
                        datasets: datasets,
                    },
                    plugins: [
                        {
                            afterLayout: function (chart) {
                                var ctx = chart.ctx;
                                var xAxis = chart.scales.x;

                                if (xAxis) {
                                    ctx.save();

                                    var xBottom = xAxis.getPixelForValue(0);
                                    var xTop = xAxis.getPixelForValue(100);

                                    var count = (chart.data.datasets || []).length;
                                    for (var i = 0; i < count; i++) {
                                        var gradient = ctx.createLinearGradient(xBottom, 0, xTop, 0);
                                        gradient.addColorStop(0.00, i === 0 ? '#2196e6' : i === 1 ? '#17b5aa' : '#044878');
                                        gradient.addColorStop(0.90, i === 0 ? '#1e84ca' : i === 1 ? '#149c92' : '#043a60');
                                        gradient.addColorStop(1.00, '#d2152b');
                                        chart.data.datasets[i].backgroundColor = gradient;
                                    }
                                    ctx.restore();
                                }
                            },
                        },
                    ],
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        indexAxis: 'y',
                        scales: {
                            x: {
                                min: 0, max: 100,
                                grid: {
                                    color: '#c9c9c9',
                                    borderColor: '#c9c9c9',
                                    tickColor: '#c9c9c9',
                                    drawBorder: true,
                                    borderDash: [2, 2],
                                    tickBorderDash: [],
                                },
                                ticks: {
                                    maxRotation: 0,
                                    color: '#868889',
                                    font: {
                                        size: 10,
                                    }
                                },
                                title: {
                                    display: false,
                                }
                            },
                            y: {
                                grid: {
                                    color: '#c9c9c9',
                                    borderColor: '#c9c9c9',
                                    tickColor: '#c9c9c9',
                                    drawBorder: true,
                                    borderDash: [2, 2],
                                    tickBorderDash: [],
                                },
                                ticks: {
                                    maxRotation: 0,
                                    color: '#868889',
                                    autoSkipPadding: 0,
                                    font: {
                                        size: 10,
                                    }
                                },
                                title: {
                                    display: false,
                                },
                            }
                        },
                        interaction: {
                            mode: 'nearest',
                            intersect: false,
                        },
                        elements: {
                            point: {
                                radius: 0,
                                hitRadius: 3,
                                hoverRadius: 6,
                                hoverBorderColor: '#ffffff',
                                hoverBorderWidth: 6,
                                hoverBackgroundColor: 'transparent'
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                backgroundColor: '#ffffff',
                                borderRadius: 10,
                                bodyColor: '#868889',
                                displayColors: false,
                                caretPadding: 12,
                                padding: {
                                    left: 4,
                                    right: 4,
                                    top: 12,
                                    bottom: 12,
                                },
                                callbacks: {
                                    title: function () { return ''; },
                                    label: function (context) {
                                        return context.parsed.overload ? 'OVERLOAD' : parseFloat(context.parsed.x).toFixed(0) + ' %';
                                    }
                                }
                            }
                        }
                    }
                };

                if (chartInstance) chartInstance.destroy();
                canvas.parentNode.style.height = (50 + (calculations.length == 3 ? 300 : calculations.length == 2 ? 210 : calculations.length == 1 ? 120 : (calculations.length * 3 * 33))) + 'px';
                chartInstance = new Chart(canvas, config);

                return chartInstance;
            };

            _chartLoadStatic = createChart(_chartLoadStatic, _charts.loadstatic, $.map(calculations, function (calculation, i) {
                return {
                    label: calculation.robot.name,
                    data: [
                        formatLoad(calculation.calculation.LoadStaticJ4, 'J4', calculation.calculation.LoadStaticJ4Overload),
                        formatLoad(calculation.calculation.LoadStaticJ5, 'J5', calculation.calculation.LoadStaticJ5Overload),
                        formatLoad(calculation.calculation.LoadStaticJ6, 'J6', calculation.calculation.LoadStaticJ6Overload),
                    ],
                    parsing: false,
                    borderRadius: {
                        topLeft: 0,
                        topRight: 4,
                        bottomLeft: 0,
                        bottomRight: 4,
                    },
                    backgroundColor: i === 0 ? '#4595e0' : i === 1 ? '#53b2aa' : '#1b4874',
                    maxBarThickness: 20,
                }
            }));

            _chartLoadDynamic = createChart(_chartLoadDynamic, _charts.loaddynamic, $.map(calculations, function (calculation, i) {
                return {
                    label: calculation.robot.name,
                    data: [
                        formatLoad(calculation.calculation.LoadDynamicJ4, 'J4', calculation.calculation.LoadDynamicJ4Overload),
                        formatLoad(calculation.calculation.LoadDynamicJ5, 'J5', calculation.calculation.LoadDynamicJ5Overload),
                        formatLoad(calculation.calculation.LoadDynamicJ6, 'J6', calculation.calculation.LoadDynamicJ6Overload),
                    ],
                    parsing: false,
                    borderRadius: {
                        topLeft: 0,
                        topRight: 4,
                        bottomLeft: 0,
                        bottomRight: 4,
                    },
                    backgroundColor: i === 0 ? '#4595e0' : i === 1 ? '#53b2aa' : '#1b4874',
                    maxBarThickness: 20,
                }
            }));
        })();

        for (var i = 0; i < calculations.length; i++) {
            var c = calculations[i];

            if (c.calculation.LoadDynamicError || c.calculation.LoadStaticError) {
                window._toast({
                    delay: 9999999,
                    key: c.robot.id + '-load',
                    type: 'error',
                    title: 'Error',
                    text: 'INVALID DATA: Specified payload is too high for robot ' + c.robot.name + '.',
                });
            } else {
                window._toast({
                    key: c.robot.id + '-load',
                    type: 'remove',
                });
            }
        }

        return calculations;
    }

    window.getPayloadInputInitial_horizontal = function (robots) {
        return getPayloadInputInitial_vertical(robots);
    };

    window.setPayloadChart_horizontal = function (input) {
        var calculate = function (input, robot) {

            var M = (input.M || 0);

            var IM = robot.SpecialOn ? (robot.IMM || robot.IM) : robot.IM;

            var calculation = {
                LxyLimitByInertiaJ4: Math.round((Math.sqrt((IM - input.lz) / M) * Math.pow(10, 3)) || 0),

                LxyCenterOfMass: Math.round(Math.pow(Math.pow(input.Lx, 2) + Math.pow(input.Ly, 2), 0.5) || 0), 
                LzCenterOfMass: input.Lz || 0,
                LxCenterOfMass: input.Lx || 0,
                LyCenterOfMass: input.Ly || 0,
            };

            calculation.LxyLimitByInertiaMax = Math.max(calculation.LxyLimitByInertiaJ4);

            calculation.LzRadius = calculation.LxyLimitByInertiaMax;
            calculation.LzRadiusSquared = Math.pow(calculation.LzRadius, 2); 

            calculation.COMRadius = calculation.LxyCenterOfMass;
            calculation.COMRadiusSquared = Math.pow(calculation.COMRadius, 2); 

            calculation.LoadDynamicJ4 = ((M * Math.pow(calculation.LxyCenterOfMass / Math.pow(10, 3), 2)) + input.lz) / IM;

            var isOverload = function (_y) { return !isFinite(_y) || (parseFloat(_y) > parseFloat(1)); };

            calculation.LoadDynamicJ4Overload = isOverload(calculation.LoadDynamicJ4);

            calculation.LxyCenterOfMassMaximum = robot.LxyCOMV1 || robot.LxyCOMV2 ? (((robot.LxyCOMV1 || 0) * Math.max(1, input.M)) + (robot.LxyCOMV2 || 0)) : robot.LxyCenterOfMassMaximum;

            calculation.LzCenterOfMassError = Math.abs(calculation.LzCenterOfMass) > (robot.LzCenterOfMassMaximum || 999999);
            calculation.LxyCenterOfMassError = Math.abs(calculation.LxyCenterOfMass) > (calculation.LxyCenterOfMassMaximum || 999999);
            calculation.LxyCenterOfMassLargeInertiaError = Math.abs(calculation.LxyCenterOfMass) > (robot.LxyCenterOfMassLargeInertiaMaximum || 999999);

            var MTotal = (input.M || 0) + (input.Ms || 0);

            calculation.LoadDynamicError =
                calculation.LoadDynamicJ4Overload ||
                calculation.LzCenterOfMassError ||
                (robot.SpecialOn ? calculation.LxyCenterOfMassLargeInertiaError : calculation.LxyCenterOfMassError) ||
                robot.load_maximum < MTotal
                ;

            return calculation;
        };

        var calculations = $.map(_analysed, function (robot, i) {
            return {
                calculation: calculate(input, robot),
                robot: robot
            };
        });

        var count = calculations.length;
        var resolution = 1;

        var xMax = Math.max.apply(Math, $.map(calculations, function (o) { return [o.calculation.LzRadius || 0, o.calculation.LxCenterOfMass || 0]; }));
        var yMax = Math.max.apply(Math, $.map(calculations, function (o) { return [o.calculation.LzRadius || 0, o.calculation.LyCenterOfMass || 0]; }));

        xMax = Math.ceil((xMax + 1) / 20) * 20;
        yMax = Math.ceil((yMax + 1) / 20) * 20;

        xMax += xMax * 0.1; 
        yMax += yMax * 0.1; 

        xMax = Math.max(xMax, yMax);
        yMax = xMax;

        if (!isFinite(xMax) || !isFinite(yMax)) {
            if (_chartPayload) _chartPayload.destroy(); _chartPayload = null;
            if (_chartLoadDynamic) _chartLoadDynamic.destroy(); _chartLoadDynamic = null;
            return;
        }

        (function () {
            var labels = [];
            var datasets = $.map(calculations, function (calculation, i) {
                return {
                    top:
                    {
                        label: calculation.robot.name,
                        data: [],
                        parsing: false,
                        borderWidth: 4,
                        borderColor: i === 0 ? '#4595e0' : i === 1 ? '#53b2aa' : '#1b4874'
                    },
                    bottom: {
                        label: calculation.robot.name,
                        data: [],
                        parsing: false,
                        borderWidth: 4,
                        borderColor: i === 0 ? '#4595e0' : i === 1 ? '#53b2aa' : '#1b4874'
                    },
                };
            });

            for (var x = -xMax; x < xMax; x += resolution) {
                var xq = Math.pow(x, 2);

                for (var d = 0; d < count; d++) {
                    var y = Math.sqrt(calculations[d].calculation.LzRadiusSquared - xq); 

                    datasets[d].top.data.push({ y: !isFinite(y) ? null : y, x: x });
                    datasets[d].bottom.data.push({ y: !isFinite(y) ? null : -y, x: x });
                }

                labels.push(x);
            }

            datasets = $.map(datasets, function (e, i) { return [e.top, e.bottom]; });

            if (count) {
                var image = new Image();
                image.width = 26;
                image.height = 26;
                image.src = $('.center-of-mass-image').attr('src');

                datasets.push({
                    label: 'Center of mass',
                    parsing: false,
                    data: [{
                        x: calculations[0].calculation.LxCenterOfMass,
                        y: calculations[0].calculation.LyCenterOfMass,
                    }],
                    pointStyle: [image],
                    elements: {
                        point: {
                            radius: 1,
                            hitRadius: 10,
                        }
                    },
                });
            }

            var config = {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: datasets,
                },
                plugins: [
                    {
                        afterDraw: function (chart) {
                            var ctx = chart.ctx;
                            var x = $.grep((chart.boxes || []), function (e, i) { return e.id === 'x'; })[0];
                            var y = $.grep((chart.boxes || []), function (e, i) { return e.id === 'y'; })[0];

                            var xx = { x: x.left + (x.width / 2), y: x.height / 2 };
                            var yy = { x: y.right - (y.width / 2) + 18, y: (y.height / 2) + chart.options.layout.padding.top };

                            ctx.save();

                            ctx.fillStyle = '#868889';
                            ctx.font = '14px Ubuntu, sans-serif';
                            ctx.textBaseline = 'middle';
                            ctx.textAlign = 'center';
                            ctx.translate(xx.x, xx.y);
                            ctx.fillText('Ly [mm]', 0, 0);

                                                           ctx.restore();
                            ctx.save();

                            ctx.fillStyle = '#868889';
                            ctx.font = '14px Ubuntu, sans-serif';
                            ctx.textSize = '14px';
                            ctx.textBaseline = 'middle';
                            ctx.textAlign = 'center';
                            ctx.translate(yy.x, yy.y);
                            ctx.fillText('Lx [mm]', 0, 0);

                            ctx.restore();
                        },
                    },
                ],
                options: {
                    responsive: true,
                    aspectRatio: 1,
                    maintainAspectRatio: true,
                    scales: {
                        y: {
                            beginAtZero: false,
                            grid: {
                                display: true,
                                color: '#c9c9c9',
                                borderColor: '#c9c9c9',
                                tickColor: '#c9c9c9',
                                drawBorder: true,
                                borderDash: [2, 2],
                                tickBorderDash: [0],
                            },
                            ticks: {
                                maxRotation: 0,
                                color: '#868889',
                                font: {
                                    size: 10,
                                }
                            },
                            position: { x: 0 },
                            min: -yMax, max: yMax,
                        },
                        x: {
                            beginAtZero: false,
                            type: 'linear',
                            horizontal: true,
                            grid: {
                                display: true,
                                color: '#c9c9c9',
                                borderColor: '#c9c9c9',
                                tickColor: '#c9c9c9',
                                drawBorder: true,
                                borderDash: [2, 2],
                                tickBorderDash: [],
                            },
                            ticks: {
                                maxRotation: 0,
                                color: '#868889',
                                font: {
                                    size: 10,
                                }
                            },
                            position: { y: 0 },
                            min: -xMax, max: xMax,
                        },
                    },
                    interaction: {
                        mode: 'nearest',
                        intersect: false,
                    },
                    elements: {
                        point: {
                            radius: 0,
                            hitRadius: 3,
                            hoverRadius: 6,
                            hoverBorderColor: '#ffffff',
                            hoverBorderWidth: 6,
                            hoverBackgroundColor: 'transparent'
                        }
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 60,
                            top: 40,
                            bottom: 20,
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            backgroundColor: '#ffffff',
                            borderRadius: 10,
                            bodyColor: '#868889',
                            displayColors: false,
                            caretPadding: 12,
                            padding: {
                                left: 12,
                                right: 12,
                                top: 4,
                                bottom: 4,
                            },
                            callbacks: {
                                title: function () { return ''; },
                                label: function (context) {
                                    return context.dataset.label + ': Lx: ' + parseFloat(context.parsed.x).toFixed(2) + ' mm, Ly: ' + parseFloat(context.parsed.y).toFixed(2) + ' mm';
                                }
                            }
                        }
                    }
                }
            };

            if (_chartPayload) _chartPayload.destroy();
                _chartPayload = new Chart(_charts.payload, config);
        })();

        (function () {
            var formatLoad = function (_x, y, overload) {
                var result = {
                    x: Math.min(Math.round(_x == 0 ? 0 : ((_x || 1) * 100)), 100.0),
                    y: y,
                    overload: overload,
                };

                return result;
            };

            var createChart = function (chartInstance, canvas, datasets) {
                var config = {
                    type: 'bar',
                    data: {
                        labels: ['J4'],
                        datasets: datasets,
                    },
                    plugins: [
                        {
                            afterLayout: function (chart) {
                                var ctx = chart.ctx;
                                var xAxis = chart.scales.x;

                                if (xAxis) {
                                    ctx.save();

                                    var xBottom = xAxis.getPixelForValue(0);
                                    var xTop = xAxis.getPixelForValue(100);

                                    var count = (chart.data.datasets || []).length;
                                    for (var i = 0; i < count; i++) {
                                        var gradient = ctx.createLinearGradient(xBottom, 0, xTop, 0);
                                        gradient.addColorStop(0.00, i === 0 ? '#2196e6' : i === 1 ? '#17b5aa' : '#044878');
                                        gradient.addColorStop(0.90, i === 0 ? '#1e84ca' : i === 1 ? '#149c92' : '#043a60');
                                        gradient.addColorStop(1.00, '#d2152b');
                                        chart.data.datasets[i].backgroundColor = gradient;
                                    }
                                    ctx.restore();
                                }
                            },
                        },
                    ],
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        indexAxis: 'y',
                        scales: {
                            x: {
                                min: 0, max: 100,
                                grid: {
                                    color: '#c9c9c9',
                                    borderColor: '#c9c9c9',
                                    tickColor: '#c9c9c9',
                                    drawBorder: true,
                                    borderDash: [2, 2],
                                    tickBorderDash: [],
                                },
                                ticks: {
                                    maxRotation: 0,
                                    color: '#868889',
                                    font: {
                                        size: 10,
                                    }
                                },
                                title: {
                                    display: false,
                                }
                            },
                            y: {
                                grid: {
                                    color: '#c9c9c9',
                                    borderColor: '#c9c9c9',
                                    tickColor: '#c9c9c9',
                                    drawBorder: true,
                                    borderDash: [2, 2],
                                    tickBorderDash: [],
                                },
                                ticks: {
                                    maxRotation: 0,
                                    color: '#868889',
                                    autoSkipPadding: 0,
                                    font: {
                                        size: 10,
                                    }
                                },
                                title: {
                                    display: false,
                                },
                            }
                        },
                        interaction: {
                            mode: 'nearest',
                            intersect: false,
                        },
                        elements: {
                            point: {
                                radius: 0,
                                hitRadius: 3,
                                hoverRadius: 6,
                                hoverBorderColor: '#ffffff',
                                hoverBorderWidth: 6,
                                hoverBackgroundColor: 'transparent'
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                backgroundColor: '#ffffff',
                                borderRadius: 10,
                                bodyColor: '#868889',
                                displayColors: false,
                                caretPadding: 12,
                                padding: {
                                    left: 4,
                                    right: 4,
                                    top: 12,
                                    bottom: 12,
                                },
                                callbacks: {
                                    title: function () { return ''; },
                                    label: function (context) {
                                        return context.parsed.overload ? 'OVERLOAD' : parseFloat(context.parsed.x).toFixed(0) + ' %';
                                    }
                                }
                            }
                        }
                    }
                };

                if (chartInstance) chartInstance.destroy();
                canvas.parentNode.style.height = (50 + (calculations.length == 3 ? 100 : calculations.length == 2 ? 70 : calculations.length == 1 ? 40 : (calculations.length * 3 * 33))) + 'px';
                chartInstance = new Chart(canvas, config);

                return chartInstance;
            };

            _chartLoadDynamic = createChart(_chartLoadDynamic, _charts.loaddynamic, $.map(calculations, function (calculation, i) {
                return {
                    label: calculation.robot.name,
                    data: [
                        formatLoad(calculation.calculation.LoadDynamicJ4, 'J4', calculation.calculation.LoadDynamicJ4Overload),
                    ],
                    parsing: false,
                    borderRadius: {
                        topLeft: 0,
                        topRight: 4,
                        bottomLeft: 0,
                        bottomRight: 4,
                    },
                    backgroundColor: i === 0 ? '#4595e0' : i === 1 ? '#53b2aa' : '#1b4874',
                    maxBarThickness: 20,
                }
            }));
        })();

        for (var i = 0; i < calculations.length; i++) {
            var c = calculations[i];

            if (c.robot.SpecialOn ? c.calculation.LxyCenterOfMassLargeInertiaError : c.calculation.LxyCenterOfMassError) {
                window._toast({
                    delay: 9999999,
                    key: c.robot.id + '-lxy',
                    type: 'error',
                    title: 'Error',
                    text: 'Please adjust the Lxy coordinate to be ' + (c.robot.SpecialOn ? c.robot.LxyCenterOfMassLargeInertiaMaximum : c.robot.LxyCenterOfMassMaximum) + 'mm or less for ' + c.robot.name + ' in case of the inputted mass.',
                });
            } else {
                window._toast({
                    key: c.robot.id + '-lxy',
                    type: 'remove',
                });
            }

            if (c.calculation.LzCenterOfMassError) {
                window._toast({
                    delay: 9999999,
                    key: c.robot.id + '-lz',
                    type: 'error',
                    title: 'Error',
                    text: 'Please adjust Z coordinate to be 100mm or less for ' + c.robot.name + ' in case of the inputted mass.',
                });
            } else {
                window._toast({
                    key: c.robot.id + '-lz',
                    type: 'remove',
                });
            }

            if (c.calculation.LoadDynamicError || c.calculation.LoadStaticError) {
                window._toast({
                    delay: 9999999,
                    key: c.robot.id + '-load',
                    type: 'error',
                    title: 'Error',
                    text: 'INVALID DATA: Specified payload is too high for robot ' + c.robot.name + '.',
                });
            } else {
                window._toast({
                    key: c.robot.id + '-load',
                    type: 'remove',
                });
            }
        }

        return calculations;
    }

    window.getPayloadInputInitial_cm = function (robots) {
        return getPayloadInputInitial_vertical(robots);
    }

    window.setPayloadChart_cm = function (input) {
        return setPayloadChart_horizontal(input);
    }
});